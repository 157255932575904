import React from "react";
import { useOverrides } from "@quarkly/components";
import { Icon, Text, Box, Link, Section } from "@quarkly/widgets";
import { BsMap, BsEnvelope, BsClock } from "react-icons/bs";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"quarkly-title": "Contacts-7",
	"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6659a05bf0cb1d0025f5cba7/images/1-1.jpg?v=2024-05-31T12:21:21.965Z) 50% 50% /auto repeat scroll padding-box"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "36px 34px",
			"margin": "0px 0px 0 0px",
			"sm-grid-template-columns": "1fr",
			"md-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsMap,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#dae0e5"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"children": "Адреса"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"color": "#dae0e5",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": <>
				Dmytra Yavornytskoho Ave, 64,{" "}
				<br />
				Dnipro, Dnipropetrovsk Oblast, 49000
			</>
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsEnvelope,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#dae0e5"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"children": "Контакти"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "#dae0e5",
			"text-decoration-line": "initial",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"text-align": "center",
			"margin": "0px 0px 8px 0px",
			"href": "tel:0977186587",
			"children": "0977186587"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "#dae0e5",
			"text-decoration-line": "initial",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 0 0px",
			"text-align": "center",
			"href": "mailto:envisorare.com",
			"children": "info@envisorare.com"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsClock,
			"size": "54px",
			"margin": "0px 0px 20px 0px",
			"color": "#dae0e5"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"children": "Години роботи"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"color": "#dae0e5",
			"font": "normal 300 20px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"children": "Пн-Нд: 9:00 - 21:00"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Icon {...override("icon")} />
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Icon {...override("icon1")} />
				<Text {...override("text2")} />
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Icon {...override("icon2")} />
				<Text {...override("text3")} />
				<Text {...override("text4")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;